import React from "react";
import { Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import Page404 from "../../components/global_components/Page404";
import { Products } from "../../constants/products";
import useEnsureCanUseProduct from "../../hooks/useEnsureCanUseProduct";
import permissions from "../../selectors/permissions";
import { SubscriptionGroupsPage } from "./routes/notify/subscription-groups/SubscriptionGroupsPage";
import { NotifySubscribersPage } from "./routes/notify/subscribers/NotifySubscribersPage";

export function NotifyApp() {
  const { loggedOutRedirect, loader, productRedirect } = useEnsureCanUseProduct({
    products: Products.COMMUNICATIONS,
    permissions: permissions.can("manage_communications"),
  });
  if (loggedOutRedirect) return loggedOutRedirect;
  if (loader) return loader;
  if (productRedirect) return productRedirect;

  return (
    <ToastProvider>
      <Switch>
        <Route path='/:site/notify/subscribers' component={() => <NotifySubscribersPage />} />
        <Route path='/:site/notify/subscription-groups' component={() => <SubscriptionGroupsPage />} />
        <Route component={() => <Page404 />} />
      </Switch>
    </ToastProvider>
  );
}
