import { useCallback, useEffect, useState } from "react";

export function useBulkSelect({ totalCount }: { totalCount: number }) {
  const [bulkSelections, setBulkSelections] = useState<{ selectAll: boolean; selectExceptions: number[] }>({
    selectAll: false,
    selectExceptions: [],
  });

  const onBulkSelectAll = useCallback(() => {
    setBulkSelections((prev) => ({
      selectAll: !prev.selectAll,
      selectExceptions: [],
    }));
  }, [setBulkSelections]);

  const onBulkSelect = useCallback(
    (id: number) => {
      setBulkSelections((prev) => {
        return {
          ...prev,
          selectExceptions: prev.selectExceptions.includes(id)
            ? prev.selectExceptions.filter((exception) => exception !== id)
            : [...prev.selectExceptions, id],
        };
      });
    },
    [setBulkSelections],
  );

  const shouldBulkSelectBeChecked = useCallback(
    (id: number) => {
      if (bulkSelections.selectAll) return !bulkSelections.selectExceptions.includes(id);
      return bulkSelections.selectExceptions.includes(id);
    },
    [bulkSelections.selectAll, bulkSelections.selectExceptions],
  );

  const shouldBulkSelectAllBeIndeterminate = useCallback(() => {
    return bulkSelections.selectExceptions.length > 0;
  }, [bulkSelections.selectAll, bulkSelections.selectExceptions]);

  const shouldBulkSelectAllBeChecked = useCallback(() => {
    return bulkSelections.selectAll;
  }, [bulkSelections.selectAll]);

  const findSelectedIdsFromLoadedData = useCallback(
    <T>(data: T[], idSelector: (datum: T) => number) => {
      if (bulkSelections.selectAll) {
        return data.map(idSelector).filter((id) => !bulkSelections.selectExceptions.includes(id));
      }
      return bulkSelections.selectExceptions;
    },
    [bulkSelections.selectAll, bulkSelections.selectExceptions],
  );

  const clearBulkSelections = useCallback(() => {
    setBulkSelections({ selectAll: false, selectExceptions: [] });
  }, [setBulkSelections]);

  // Simplify the bulk selection when all items are selected
  useEffect(() => {
    if (totalCount === 0) return;
    if (totalCount === bulkSelections.selectExceptions.length) {
      setBulkSelections((prev) => ({
        selectExceptions: [],
        selectAll: !prev.selectAll,
      }));
    }
  }, [totalCount, bulkSelections.selectExceptions]);

  return {
    bulkSelections,
    onBulkSelectAll,
    onBulkSelect,
    shouldBulkSelectBeChecked,
    shouldBulkSelectAllBeChecked,
    shouldBulkSelectAllBeIndeterminate,
    clearBulkSelections,
    findSelectedIdsFromLoadedData,
  };
}
