import { createParser } from "nuqs";

// Parse data looking like this: sort=key1:asc,key2:desc
export const nuqsSortParser = createParser({
  parse(value) {
    return value.split(",").map((sort) => {
      const [id, direction] = sort.split(":");
      return { id, desc: direction === "desc" };
    });
  },
  serialize(value) {
    return value.map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`).join(",");
  },
});
