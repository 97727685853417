import React, { PropsWithChildren, useState } from "react";
import { Popup, Form, Dropdown, Label, Checkbox, Button, Message } from "semantic-ui-react";
import { useNotifySubscriptionGroups } from "../../../../hooks/useNotifySubscriptionGroups";
import { useNotifySubscriptionPreferencesUpdate } from "../../../../hooks/useNotifySubscriptionPreferencesUpdate";
import Tooltip from "../../../../../../components/Tooltip";
import { GetNotifySubscriberContactMethodsResponse } from "../../../../../../queries/notify";
import { pluralize } from "../../../../../../utils/pluralize";
import { parsedPhoneNumberAsString } from "../../../../../../components/global_components/form_components/ControlledPhoneNumber";

export function AddSubscribersToSubscriptionGroupsPopup({
  children,
  subscriberIds,
  subscriberContactMethodsById,
}: PropsWithChildren<{
  subscriberIds: number[];
  subscriberContactMethodsById: Record<number, GetNotifySubscriberContactMethodsResponse["data"][number]>;
}>) {
  const { subscriptionGroups, isSubscriptionGroupsLoading } = useNotifySubscriptionGroups({});
  const [selectedSubscriptionGroupIds, setSelectedSubscriptionGroupIds] = useState<number[]>([]);
  const [reAddUnsubscribed, setReAddUnsubscribed] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { editSubscriptionPreferences, isEditSubscriptionPreferencesLoading } =
    useNotifySubscriptionPreferencesUpdate();

  const unsubscribedContactMethods = !reAddUnsubscribed
    ? subscriberIds
        .map((id) => {
          const contactMethod = subscriberContactMethodsById[id];
          const unsubscribedSubscriptionGroups =
            contactMethod?.subscriptionPreferences
              .filter(
                (pref) =>
                  pref.type === "Unsubscribe" && selectedSubscriptionGroupIds.includes(pref.subscriptionGroupId),
              )
              .map((pref) => pref.subscriptionGroupId) ?? [];
          return {
            contactMethod,
            unsubscribedSubscriptionGroups,
          };
        })
        .filter((item) => item.unsubscribedSubscriptionGroups.length > 0)
    : [];
  const affectedSelectedGroups = unsubscribedContactMethods
    .flatMap((item) => item.unsubscribedSubscriptionGroups)
    .filter((groupId) => selectedSubscriptionGroupIds.includes(groupId));

  const buildIdentifierString = (contactMethod: GetNotifySubscriberContactMethodsResponse["data"][number]) => {
    if (contactMethod.type === "Phone") {
      return parsedPhoneNumberAsString(contactMethod.identifier);
    }
    return contactMethod.identifier;
  };

  return (
    <Popup
      trigger={children}
      open={isPopupOpen}
      position='bottom left'
      className='tw-w-96 md:tw-w-[400px]'
      flowing
      on='click'
      onOpen={() => setIsPopupOpen(true)}
      onClose={() => setIsPopupOpen(false)}
    >
      <Popup.Header className='tw-flex tw-flex-col'>
        <span>Add to Groups</span>
        <span className='subText tw-text-base tw-font-medium'>{subscriberIds.length} subscribers</span>
      </Popup.Header>

      <Popup.Content className='tw-flex tw-flex-col tw-gap-3'>
        <Form.Field className='tw-m-0'>
          <Dropdown
            options={
              subscriptionGroups?.map((group) => ({
                key: group.id,
                value: group.id,
                text: group.name,
              })) ?? []
            }
            placeholder='Subscription Group'
            clearable
            loading={isSubscriptionGroupsLoading}
            selectOnBlur={false}
            multiple
            search
            fluid
            selection
            value={selectedSubscriptionGroupIds}
            onChange={(_, { value }) => setSelectedSubscriptionGroupIds(value as number[])}
            renderLabel={(label) => {
              return (
                <Label
                  icon={affectedSelectedGroups.includes(label.value as number) ? "warning sign" : undefined}
                  className='tw-whitespace-nowrap'
                  content={label.text}
                />
              );
            }}
          />
        </Form.Field>
        <Form.Field className='tw-m-0 tw-flex tw-flex-row tw-items-center'>
          <Checkbox
            label='Re-add users who have unsubscribed'
            checked={reAddUnsubscribed}
            onChange={(_, { checked }) => setReAddUnsubscribed(!!checked)}
          />
          <Tooltip>
            This will re-add users who have previously unsubscribed from the selected groups. This may not be compliant
            with regulations unless you have a valid confirmation from the subscriber to re-add them.
          </Tooltip>
        </Form.Field>
        {!reAddUnsubscribed && !!unsubscribedContactMethods.length && (
          <Message warning className='tw-m-0'>
            <Popup
              content={unsubscribedContactMethods.map((item) => buildIdentifierString(item.contactMethod)).join(", ")}
              trigger={
                <span className='tw-underline tw-decoration-dashed'>
                  {pluralize(
                    unsubscribedContactMethods.length,
                    `${unsubscribedContactMethods.length} subscriber`,
                    `${unsubscribedContactMethods.length} subscribers`,
                  )}
                </span>
              }
              inverted
            />{" "}
            {pluralize(unsubscribedContactMethods.length, "has", "have")} unsubscribed from{" "}
            {pluralize(affectedSelectedGroups.length, "the selected group", "some selected groups")}. They will not be
            re-added.
          </Message>
        )}
        <div className='tw-flex tw-justify-end tw-gap-2'>
          <Button className='tw-m-0' secondary content='Cancel' onClick={() => setIsPopupOpen(false)} />
          <Button
            className='tw-m-0'
            primary
            content='Add'
            loading={isEditSubscriptionPreferencesLoading}
            onClick={async () => {
              await editSubscriptionPreferences({
                subscriberContactMethodIds: subscriberIds,
                subscriptionGroupIds: selectedSubscriptionGroupIds,
                type: "Subscribe",
                overwriteExistingPreference: reAddUnsubscribed,
              });
              setIsPopupOpen(false);
              setSelectedSubscriptionGroupIds([]);
              setReAddUnsubscribed(false);
            }}
          />
        </div>
      </Popup.Content>
    </Popup>
  );
}
