import { useMutation, useQueryCache } from "react-query";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";
import { postNotifySubscriberContactMethodsOptOut } from "../../../queries/notify";
import { buildNotifySubscriberContactMethodsQueryKey } from "./useInfiniteNotifySubscriberContactMethods";

export function useNotifySubscriberContactMethodOptOut() {
  const networkHeaders = useNetworkHeaders();
  const queryCache = useQueryCache();

  const [optOutSubscriberContactMethodMutation, { isLoading: isOptOutSubscriberContactMethodLoading }] = useMutation(
    postNotifySubscriberContactMethodsOptOut(networkHeaders),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(buildNotifySubscriberContactMethodsQueryKey());
      },
    },
  );

  const optOutSubscriberContactMethod = async ({
    subscriberContactMethodIds,
  }: {
    subscriberContactMethodIds: number[];
  }) => {
    return await optOutSubscriberContactMethodMutation({
      subscriberContactMethodIds,
    });
  };

  return { optOutSubscriberContactMethod, isOptOutSubscriberContactMethodLoading };
}
