import { coreService } from "../services/coreService";
import { buildQuery } from "../lib/network";
import { fetch } from "../lib/network";
import AppSettings from "../config";

export const getAgencySettings = (headers: any) => () =>
  buildQuery(coreService.agencySettingsList({ headers }), (x) => x.data);

export const getPickList = (networkHeaders: any, pickListName: string) => () => {
  return fetch(`${AppSettings.GOGOV_BASE_API_URL}/core/agency/picklist/${pickListName}`, {
    method: "GET",
    headers: networkHeaders,
  }).then((res: any) => {
    if (res.status === 200) {
      return res.json();
    }
    if (res.error) throw new Error(res.error);
    throw new Error(`Query failed with status ${res.status}`);
  });
};
