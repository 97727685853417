import { useMutation, useQueryCache } from "react-query";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";
import {
  putNotifySubscriptionGroup,
  UpdateNotifySubscriptionGroupBody,
  UpdateNotifySubscriptionGroupResponse,
} from "../../../queries/notify";
import { buildNotifySubscriptionGroupsQueryKey } from "./useNotifySubscriptionGroups";

export function useNotifySubscriptionGroupEdit({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) {
  const networkHeaders = useNetworkHeaders();
  const queryCache = useQueryCache();

  const [editSubscriptionGroupMutation, { isLoading: isEditSubscriptionGroupLoading }] = useMutation(
    putNotifySubscriptionGroup(networkHeaders),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(buildNotifySubscriptionGroupsQueryKey());
        onSuccess();
      },
      onError,
    },
  );

  const editSubscriptionGroup = async (
    id: number,
    data: UpdateNotifySubscriptionGroupBody,
  ): Promise<UpdateNotifySubscriptionGroupResponse | undefined> => {
    return await editSubscriptionGroupMutation({ id, body: data });
  };

  return { editSubscriptionGroup, isEditSubscriptionGroupLoading };
}
