import classNames from "classnames";
import React from "react";
import { Popup } from "semantic-ui-react";

interface IOverflowTextTooltip {
  text?: string;
  content?: any;
  dateTooltip?: any;
  noInvert?: boolean;
  className?: string;
}

const OverflowTextTooltip = ({ text, content, dateTooltip, noInvert, className }: IOverflowTextTooltip) => {
  return (
    <Popup
      content={text ? text : dateTooltip ? dateTooltip : content}
      inverted={!noInvert}
      mouseEnterDelay={500}
      mouseLeaveDelay={200}
      on='hover'
      trigger={text ? <p className={classNames("ellipses-overflow", className)}>{text}</p> : content}
    />
  );
};

export default OverflowTextTooltip;
