import { useSelector } from "react-redux";
import network from "../selectors/network";
import { getAgencySettings } from "../queries/agency_settings";
import { useQuery } from "react-query";

export function useTimezone({ defaultToAgencyTimezone = true }: { defaultToAgencyTimezone?: boolean } = {}) {
  const networkHeaders = useSelector(network.defaultHeaders);

  const { data, isLoading } = useQuery("agencySettings", getAgencySettings(networkHeaders), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const clientIsInAgencyTimezone = data?.timezoneInfo.timezone === clientTimezone;

  const agencyTimezoneInfo = data?.timezoneInfo;
  const clientTimezoneInfo = {
    timezone: clientTimezone,
    utcOffset: new Date().getTimezoneOffset() / -60,
  };

  const defaultTimezoneInfo = defaultToAgencyTimezone
    ? agencyTimezoneInfo ?? clientTimezoneInfo // Fallback to client timezone if agency timezone is not set
    : clientTimezoneInfo;

  return {
    agencyTimezoneInfo,
    clientTimezoneInfo,
    defaultTimezoneInfo,
    clientIsInAgencyTimezone,
    isLoading,
  };
}
