import React from "react";
import TopNavigation from "../../../components/global_components/TopNavigation";

export function NotifyTopNav() {
  return (
    <TopNavigation
      tabs={[
        {
          label: "Notifications",
          activeWhenURLIncludes: "/notify/notifications",
          to: "/communications",
        },
        {
          label: "Subscribers",
          activeWhenURLIncludes: "/notify/subscribers",
          to: "/notify/subscribers",
        },
        {
          label: "Subscription Groups",
          activeWhenURLIncludes: "/notify/subscription-groups",
          to: "/notify/subscription-groups",
        },
      ]}
    />
  );
}
