import { useMutation, useQueryCache } from "react-query";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";
import { deactivateNotifySubscriptionGroup, DeactivateNotifySubscriptionGroupResponse } from "../../../queries/notify";
import { buildNotifySubscriptionGroupsQueryKey } from "./useNotifySubscriptionGroups";

export function useNotifySubscriptionGroupDeactivate({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) {
  const networkHeaders = useNetworkHeaders();
  const queryCache = useQueryCache();

  const [deactivateSubscriptionGroupMutation, { isLoading: isDeactivateSubscriptionGroupLoading }] = useMutation(
    deactivateNotifySubscriptionGroup(networkHeaders),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(buildNotifySubscriptionGroupsQueryKey());
        onSuccess();
      },
      onError,
    },
  );

  const deactivateSubscriptionGroup = async (
    id: number,
  ): Promise<DeactivateNotifySubscriptionGroupResponse | undefined> => {
    return await deactivateSubscriptionGroupMutation({ id });
  };

  return { deactivateSubscriptionGroup, isDeactivateSubscriptionGroupLoading };
}
