import React from "react";
import { useTimezone } from "../../hooks/useTimezone";
import { formatInTimeZone } from "date-fns-tz";

type Props = {
  value: string | number | Date | null | undefined;
  formatString?: string;
  timezone?: string;
  timeWithRespectToAgencyTimezone?: boolean;
  disableAutoTimezoneIndicator?: boolean;
};

/**
 * @see {@link https://date-fns.org/v4.1.0/docs/format} for format string options
 */
export function ZonedDateTime(props: Props) {
  const {
    value,
    formatString = "M/d/yyyy, h:mm a",
    timezone: timezoneProp,
    timeWithRespectToAgencyTimezone = true,
    disableAutoTimezoneIndicator = false,
  } = props;

  const timezoneInfo = useTimezone({ defaultToAgencyTimezone: timeWithRespectToAgencyTimezone });
  if (!value) return null;

  const timezone = !!timezoneProp ? timezoneProp : timezoneInfo.defaultTimezoneInfo.timezone;

  const shouldHideTimezone =
    !timeWithRespectToAgencyTimezone || timezoneInfo.clientIsInAgencyTimezone || disableAutoTimezoneIndicator;

  const formatStringWithTimezone = shouldHideTimezone ? formatString : `${formatString} z`;
  const formattedDate = formatInTimeZone(value, timezone!, formatStringWithTimezone);

  return <time dateTime={new Date(value).toISOString()}>{formattedDate}</time>;
}
